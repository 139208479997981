<template>
  <div class="mcia-outer-container">
    <Banner
      :title="corporateAnalyticsData.title"
      :imageName="corporateAnalyticsData.backgroundImage"
      :height="corporateAnalyticsData.bannerHeight"
    />
    <product-details
      :description="fundamentalsToolData.details"
      :imageFileName="fundamentalsToolData.imageFileName"
      @redirectToGetStartedForm="scrollToSection('fundamentals-tool-sign-in-form')"
    />
    <key-features :featuresList="fundamentalsToolData.keyFeatures" />
    <key-benefits :benefitsList="fundamentalsToolData.keyBenefits" @redirectToGetStartedForm="scrollToSection('fundamentals-tool-sign-in-form')"/>
    <div class="mcia-get-started-section">
      <div class="mcia-inner-container">
        <get-started-form form-id="fundamentals-tool-sign-in-form" @toggleNotification="getStartedThankYouToggled" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Banner from '@/components/Common/Banner.vue';
import ProductDetails from '@/components/Product/ProductDetails.vue';
import fundamentalsToolProductData from '@/data/fundamentalsTool.json';
import KeyFeatures from '@/components/Product/KeyFeatures.vue';
import KeyBenefits from '@/components/Product/KeyBenefits.vue';
import GetStartedForm from '@/components/GetStartedForm/GetStartedForm.vue';
import BannerData from '@/data/banner.json';

export default {
  name: 'FundamentalsTool',
  components: {
    Banner,
    ProductDetails,
    KeyFeatures,
    KeyBenefits,
    GetStartedForm,
  },
  created() {
    this.saveSelectedProducts(['corporate_analytics']);
  },
  mounted() {
    this.saveShowSignInButton(true);
    this.saveActiveSignInLink(this.fundamentalsToolData?.signInLink);
  },
  beforeDestroy() {
    this.saveShowSignInButton(false);
  },
  data() {
    return {
      fundamentalsToolData: fundamentalsToolProductData,
      showNotification: false,
      corporateAnalyticsData: BannerData.corporateAnalyticsBanner,
    };
  },
  methods: {
    ...mapActions({
      saveActiveSignInLink: 'signIn/setActiveSignInLink',
      saveShowSignInButton: 'signIn/setSignInVisibility',
      saveSelectedProducts: 'signIn/setSelectedProducts',
    }),
    scrollToSection(elementId) {
      if (elementId) {
        let element = document?.getElementById(elementId);
        let headerOffset = 95;
        let elementPosition = element?.getBoundingClientRect()?.top;
        let offsetPosition = elementPosition + window?.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    },
    getStartedThankYouToggled(toggle) {
      this.showNotification = toggle;
      if (this.showNotification) {
        setTimeout(() => {
          this.showNotification = false;
        }, 5000);
      }
    },
  }
};
</script>

<style scoped lang="scss">
.mcia-get-started-section {
  border-top: $mds-border-control;
  border-top-style: dashed !important;
  width: 100%;
  height: 100%;
  padding-top: $mds-space-2-x;
  padding-bottom: $mds-space-2-x;
}
.mds-modal-body {
  overflow: hidden !important;
}
</style>
